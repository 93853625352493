import React from 'react';
import { Box } from '@heycater/design-system';
import { Link as MuiLink } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { CITIES } from 'static_pages/home/constants';
import Container from 'system/components/Container';

function Caterings() {
  const { t } = useTranslation('static_pages');

  return (
    <Container as="nav">
      <Box pt={{ xs: 2, sm: 6 }} id="cateringCityLinksSection">
        <Grid container spacing={2}>
          {CITIES.map((city) => (
            <Grid
              component="section"
              key={city.name}
              item
              md={3}
              sm={6}
              xs={12}
            >
              <Box mb={3}>
                <h6 className="sr-only">
                  Catering in {t(`common:cities.${city.name}`)}
                </h6>
                <Link href={city.url} passHref locale={false}>
                  <MuiLink variant="h6" color="textPrimary" display="block">
                    Catering in {t(`common:cities.${city.name}`)}
                  </MuiLink>
                </Link>
              </Box>
              <Box>
                {city.events.map((event) => (
                  <Box mb={1} key={event.name}>
                    <Link href={event.link} passHref>
                      <MuiLink
                        variant="body2"
                        color="textPrimary"
                        display="block"
                      >
                        {t(`caterings.${event.name}`)}{' '}
                        {t(`common:cities.${city.name}`)}
                      </MuiLink>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default Caterings;
